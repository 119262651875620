import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import './NavMenu.css';
import SiteLogo from "../assets/img/DELogo-Main-S1-GreenTransparent-750x150.png";

const NavMenu = () => {
    const [collapsed, setCollapsed] = useState(true);
    const location = useLocation();

    useEffect(() => {
        switch (window.location.hash) {
            case '#stats': {
                scrollToElement('stats');
                break;
            }
            case '#home': {
                scrollToElement('home');
                break;
            }
            case '#dcf': {
                scrollToElement('dcf');
                break;
            }
            case '#contact': {
                scrollToElement('contact');
                break;
            }
            default: {
                break;
            }
        }
    }, []);

    const showCenteredNav = useCallback( () => {
        return location.pathname === '/dcf';
    },[location.pathname]);

    useEffect(() => {
        setCollapsed(true);
        showCenteredNav();
    }, [location,showCenteredNav]);

    const toggleNavbar = () => {
        setCollapsed(!collapsed);
    }

    const scrollToElement = (elementId) => {
        document.getElementById(elementId).scrollIntoView({ behavior: 'smooth' });
    };

    

    const clickDonate = () => {
        window.open('https://www.paypal.com/donate/?hosted_button_id=VL3W5GL2CYY7U','_blank');
    }

    return (
        <header className={location.pathname === '/dcf' ? 'red' : 'yellow'}>
            <Navbar className={['navbar-expand-sm', 'navbar-toggleable-sm', 'ng-white', 'mb-3', 'fixed-top', 'px-0', 'px-sm-3', showCenteredNav() ? 'container' : 'container-fluid'].join(" ")} dark>
                <NavbarBrand tag={Link} to="/">
                    <img src={SiteLogo} className="logo-img-class" alt="Digital Emissions"></img>
                </NavbarBrand>

                <NavbarToggler onClick={toggleNavbar} className="mr-2" />
                <Collapse className="d-sm-inline-flex flex-sm-row-reverse text-end custom-mobile-nav" isOpen={!collapsed} navbar>
                    <ul className="navbar-nav flex-grow">
                        <NavItem>
                            <NavLink tag={Link} className="text-green" to="/#products" onClick={() => scrollToElement('products')} >What we do</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} className="text-green" to="/#dcf" onClick={() => scrollToElement('dcf')}>DCF Models</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} className="text-green" to="/#contact" onClick={() => scrollToElement('contact')}>Contact</NavLink>
                        </NavItem>
                        <NavItem>
                            <button className="btn btn-green btn-sm mt-1 ms-3" onClick={clickDonate}>Donate</button>
                        </NavItem>
                    </ul>
                </Collapse>
            </Navbar>
        </header>
    );
};

export default NavMenu;