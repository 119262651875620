import React, { useState } from 'react';
import styles from './DeDropDown.module.css';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { CustomDropdownProps} from "components/common/DeDropDown/DeDropDown.model";
import { SelectChangeEvent } from '@mui/material/Select';

export default function DeDropDown(drdProps:CustomDropdownProps) {
    console.log("drdProps",drdProps);
    const [emptyCssClass, setEmptyCssClass] = useState("validation-empty");
    function onChange(event: SelectChangeEvent) {
      if(event.target.value === ""){
        setEmptyCssClass("validation-empty");
      }else{
        setEmptyCssClass("validation-full");
      }
      drdProps.onChange(event, <span></span>);
    }  
    return(
        <Box sx={{ minWidth: 120 }} className={styles.topdedropdown}>
        <FormControl className={styles.formcontrol} style={{minWidth: `${drdProps.width}px`}}>
          <InputLabel id={`${drdProps.id}-label`} classes={{ shrink: styles.lblshrink }} className={styles.selectlabel}>{drdProps.label}</InputLabel>
          <Select
            labelId={`${drdProps.id}-label`}
            id={drdProps.id}
            value={drdProps.value}
            label={drdProps.label}
            onChange={onChange}
            className={[styles.selectdropdown,drdProps.cssclass, emptyCssClass].join(" ")}
            classes={{ icon: styles.drdicon, select:styles.drdselect }}
            MenuProps={{className:styles.menustyle }}
          >
            {drdProps.options.map((option) => (
              <MenuItem  className={styles.menustyle} key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
            
          </Select>
        </FormControl>
      </Box>
    );
}