import React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import styles from './DeNumberInput.module.css';
import { DeNumberInputProps } from './DeNumberInput.model';


function DeNumberInput(props: DeNumberInputProps) {

  const handleIncrement = () => {
    props.onChange(props.value + 1);
  };

  const handleDecrement = () => {
    props.onChange(props.value - 1);
  };

  return (
    <TextField
      id={props.id}
      label={props.label}
      value={props.value}
      variant="outlined"
      style={{width: `${props.width}px`}}
      onChange={(event) => {
        const value = event.target.value;
        props.onChange(value === '' ? 0 : parseInt(value));
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton className={styles.removebutton} onClick={handleDecrement}>
              <RemoveIcon fontSize="inherit"/>
            </IconButton>
            <IconButton className={styles.addbutton} onClick={handleIncrement}>
              <AddIcon fontSize="inherit"/>
            </IconButton>
          </InputAdornment>
        ),
        className: styles.textfield,
        classes: {
          notchedOutline: styles.notchedoutline
        }
      }}
      InputLabelProps={{
        shrink: true,
        classes: {
          shrink: styles.shrink, // Map the shrink class to the CSS class name 
        },
      }}
    />
  );
}

export default DeNumberInput;
