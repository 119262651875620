import './FirstPage.css';

export const FirstPage = () => {
  return (
    <section id="home" className="page-container">
      <div className="video-background">
              <video poster="../../../assets/img/videoPoster.jpg" autoPlay muted loop playsInline>
                  <source src="/bg.mp4?playsinline=1" type="video/mp4" />    
        </video>
      </div>
      <div className="masthead">
        <div className="masthead-content text-white">
          <div className="container-fluid px-4 px-lg-0">
            <div className="font-header lh-1 mb-1 color-cucumber">
              The future is
            </div>
            <div className="font-header lh-1 mb-2 color-cucumber">
              Digital and Sustainable
            </div>
            <div className="mb-5 fs-5">
              Advancing the twin transition in the digital age
            </div>
          </div>
        </div>
      </div>

      <div className="social-icons">
        <div className="d-flex flex-row flex-lg-column justify-content-center align-items-center h-100 mt-3 mt-lg-0">
          <a
            className="btn btn-dark m-3"
                      href="https://www.linkedin.com/company/digitalco2e/"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-linkedin"></i>
          </a>
          <a
            className="btn btn-dark m-3"
            href="https://www.facebook.com/DigitalCO2e/"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-facebook-f"></i>
          </a>
          <a
            className="btn btn-dark m-3"
            href="https://www.instagram.com/DigitalCO2e/"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-instagram"></i>
          </a>
        </div>
      </div>
    </section>
  );
};

