import React from 'react';
import styles from './FormSocial.module.css';
import DoYouKnow from 'components/Dcf/DoYouKnow/DoYouKnow';
import DoYouKnowImage from "assets/img/Present_Social.jpg";
import { DoYouKnowProps } from "components/Dcf/DoYouKnow/DoYouKnow.model";
import { useDispatch, useSelector } from 'react-redux';
import { DeNumberInputProps } from 'components/common/DeNumberInput/DeNumberInput.model';
import { setFormSocial } from '../dcf.actions';
import DeNumberInput from 'components/common/DeNumberInput/DeNumberInput';
import { CustomSliderProps } from 'components/common/DeSlider/DeSlider.model';
import IconFacetime from 'assets/img/ICON_facetime.png';
import IconInstagram from 'assets/img/ICON_instagram.png';
import IconFacebook from 'assets/img/ICON_facebook.png';
import IconTiktok from 'assets/img/ICON_tik-tok.png';
import DeSlider from 'components/common/DeSlider/DeSlider';

export default function FormSocial({value,nextButtonClick,backButtonClick}:any) {
  const { sms,
    whatsapp,
    tweets,
    zoomhours,
    facetime,
    instagram,
    facebook,
    tiktok} = useSelector((state: any) => state.dcf.formSocial);
  const dispatch = useDispatch();
  
    const tileProps:DoYouKnowProps={
        imgsrc:DoYouKnowImage,
        header: (
            <div style={{ width: '200px' }}>
              <span>Did you know?</span>
            </div>
          ),
          body: (
            <div style={{ width: '160px' }}>
              <div>
                One conservative estimate finds TikTok and Facebook generate more CO2e annually than it would take to fly the entire population of London to New York and back.
              </div>
            </div>
          ),
         position:"left-bottom"
    }
    const numberSms:DeNumberInputProps={
      label:"Weekly SMS Msgs",
      value:sms,
      onChange:(value: number) =>{
        dispatch(setFormSocial(value, whatsapp, tweets, zoomhours, facetime, instagram, facebook, tiktok));
      },
      width:220,
      id:"deNumberSms"
    }
    const numberWhatsApp:DeNumberInputProps={
      label:"Weekly WhatsApp Msgs",
      value:whatsapp,
      onChange:(value: number) =>{
        dispatch(setFormSocial(sms, value, tweets, zoomhours, facetime, instagram, facebook, tiktok));
      },
      width:220,
      id:"deNumberWhatsApp"
    }
    const numberTweets:DeNumberInputProps={
      label:"Weekly Tweets",
      value:tweets,
      onChange:(value: number) =>{
        dispatch(setFormSocial(sms, whatsapp, value, zoomhours, facetime, instagram, facebook, tiktok));
      },
      width:220,
      id:"deNumberTweets"
    }
    const numberZoom:DeNumberInputProps={
      label:"Weekly Zoom Hours",
      value:zoomhours,
      onChange:(value: number) =>{
        dispatch(setFormSocial(sms, whatsapp, tweets, value, facetime, instagram, facebook, tiktok));
      },
      width:220,
      id:"deNumberZoom"
    }
    const sliderFacetime:CustomSliderProps={
      id:"vertical",
      orientation:'vertical',
      invert:true,
      min:0,
      max:100,
      text:"Facetime",
      logo:IconFacetime,
      value:facetime,
      tooltip:" hours weekly",
      onChange: (value: number) =>{
        dispatch(setFormSocial(sms, whatsapp, tweets, zoomhours, value, instagram, facebook, tiktok));
      },
      onAfterChange: (value: number) =>{
        dispatch(setFormSocial(sms, whatsapp, tweets, zoomhours, value, instagram, facebook, tiktok));
      }
    }
    const sliderInstagram:CustomSliderProps={
      id:"vertical",
      orientation:'vertical',
      invert:true,
      min:0,
      max:100,
      text:"Instagram",
      logo:IconInstagram,
      value:instagram,
      tooltip:" hours weekly",
      onChange: (value: number) =>{
        dispatch(setFormSocial(sms, whatsapp, tweets, zoomhours, facetime, value, facebook, tiktok));
      },
      onAfterChange: (value: number) =>{
        dispatch(setFormSocial(sms, whatsapp, tweets, zoomhours, facetime, value, facebook, tiktok));
      }
    }
    const sliderFacebook:CustomSliderProps={
      id:"vertical",
      orientation:'vertical',
      invert:true,
      min:0,
      max:100,
      text:"Facebook",
      logo:IconFacebook,
      value:facebook,
      tooltip:" hours weekly",
      onChange: (value: number) =>{
        dispatch(setFormSocial(sms, whatsapp, tweets, zoomhours, facetime, instagram, value, tiktok));
      },
      onAfterChange: (value: number) =>{
        dispatch(setFormSocial(sms, whatsapp, tweets, zoomhours, facetime, instagram, value, tiktok));
      }
    }
    const sliderTiktok:CustomSliderProps={
      id:"vertical",
      orientation:'vertical',
      invert:true,
      min:0,
      max:100,
      text:"TikTok",
      logo:IconTiktok,
      value:tiktok,
      tooltip:" hours weekly",
      onChange: (value: number) =>{
        dispatch(setFormSocial(sms, whatsapp, tweets, zoomhours, facetime, instagram, facebook, value));
      },
      onAfterChange: (value: number) =>{
        dispatch(setFormSocial(sms, whatsapp, tweets, zoomhours, facetime, instagram, facebook, value));
      }
    }
    return(
        <div  className={[styles.topsocial,"topform"].join(" ")}>  
            <div className="row">
                <div className="col-lg-6 col-12 col-sm-12"> 
                  <div className={styles.topformsocial}>
                    <div className="formcontent  pt-4 pb-4">
                      <div className="row">
                        <div className="col-lg-5 col-md-5 col-sm-12">
                          <div className="row pb-4 pt-4">
                            <div className="col-12 text-center">
                              <DeNumberInput {...numberSms} />
                            </div>
                          </div>
                          <div className="row pb-4">
                            <div className="col-12 text-center">
                              <DeNumberInput {...numberWhatsApp} />
                            </div>
                          </div>
                          <div className="row pb-4">
                            <div className="col-12 text-center">
                              <DeNumberInput {...numberTweets} />
                            </div>
                          </div>
                          <div className="row pb-4">
                            <div className="col-12 text-center">
                              <DeNumberInput {...numberZoom} />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-7 col-md-7 col-sm-12">
                          <div className="row">
                            <div className="col-3">
                              <DeSlider {...sliderFacetime}></DeSlider>
                            </div>
                            <div className="col-3">
                              <DeSlider {...sliderInstagram}></DeSlider>
                            </div>
                            <div className="col-3">
                              <DeSlider {...sliderFacebook}></DeSlider>
                            </div>
                            <div className="col-3">
                              <DeSlider {...sliderTiktok}></DeSlider> 
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                            <div className="col-6 pt-2">
                                <span className="backbutton" onClick={()=>{backButtonClick(value)}}>&lt; Back</span>
                            </div>
                            <div className="col-6 text-end">
                                 <button className="btn btn-green btn-sm mt-1" onClick={()=>{nextButtonClick(value)}}>Next</button>
                            </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-12 col-sm-12">
                     <DoYouKnow {...tileProps}></DoYouKnow>
                </div>
            </div>
        </div>
    );
}