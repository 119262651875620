import React, {  useState } from 'react';
import styles from './DoYouKnow.module.css';
import { DoYouKnowProps } from 'components/Dcf/DoYouKnow/DoYouKnow.model';
import { Fade, Slide } from '@mui/material';

export default function DoYouKnow(tileProps: DoYouKnowProps) {
  const [show, setShow] = useState(true);

  const backgroundStyle = {
    backgroundImage: `linear-gradient(rgba(34,42,53,.5), rgba(34,42,53,.6)),url(${tileProps.imgsrc})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '365px',
    width: '550px',
    position: 'relative',
    overflow:'hidden'
    
  } as React.CSSProperties;

  const textContainerStyle = {
    position: 'absolute',
    bottom: tileProps.position.includes('bottom') ? '0' : 'auto',
    top: tileProps.position.includes('top') ? '0' : 'auto',
    left: tileProps.position.includes('left') ? '0' : 'auto',
    right: tileProps.position.includes('right') ? '0' : 'auto',
    padding: '20px',
    color: '#fff',
  } as React.CSSProperties;

  const handleExited = () => {
    setShow(false);
  };

  return (
    <Fade in={show} timeout={1000} onExited={handleExited}>
      <div className={styles.topdoyouknow} style={backgroundStyle}>
        <Slide in={show} direction="up" timeout={1000}>
          <div style={textContainerStyle}>
            <div className={[styles.header, 'pb-3'].join(' ')}>{tileProps.header}</div>
            <div className={styles.body}>{tileProps.body}</div>
          </div>
        </Slide>
      </div>
    </Fade>
  );
}
