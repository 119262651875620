import FirstImage from '../../../assets/img/Section3-Icon-cloud.svg';
import SecondImage from '../../../assets/img/Section3-Icon-lightbulb.svg';
import ThirdImage from '../../../assets/img/Section3-Icon-puzzle.svg';

export interface IFlipCard {
  image: string;
  frontText: string;
  backText: string;
}

export const FlipCardsConfig: IFlipCard[] = [
  {
    image: FirstImage,
    frontText: 'Provide Digital Carbon Footprint Solutions',
    backText: 'addressing the current lack of IT tools to assess digital carbon footprints',
  },
  {
    image: SecondImage,
    frontText:
      'Raise Public Awareness about the implications of unchecked digitalization',
    backText: 'educate the digital community about digital community and digital justice',
  },
  {
    image: ThirdImage,
    frontText: 'Galvanize Partnerships to reduce digital carbon emissions',
    backText: 'forging partnerships among researchers, policy makers, and industry practicioners to mitigate the impact of an ever-growing digital world',
  },
];
