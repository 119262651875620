import { Link } from 'react-router-dom';
import CalculatorIcon from '../../../assets/img/Section4-Icon-ContinualCloud.png';
import CalculatorPhone from '../../../assets/img/Section4-IPADs.svg';
import './FourthPage.css';

export const FourthPage = () => {
    const handleClick = () => {
        window.location.href = '/dcf';
    };
    return (
        <section id="dcf" className="page-container">
            <div className="container-fluid d-flex justify-content-center align-items-center dcf-calc pt-0">
                <div className="row d-flex justify-content-center align-items-end">

                    <div className="col-sm-8 col-md-8 col-xl-4 bg-dark-transparent text-center py-5 px-3 rounded what-we-do-text-container">
                        <img
                            src={CalculatorIcon}
                            className="mt-2"
                            style={{ maxWidth: '50px' }}
                            alt=""
                        />
                        <div className="font-header lh-1 text-white mt-5">
                            <Link to="/dcf" className="text-green text-decoration-none">DCF Lifestyle Calculator</Link>
                        </div>
                        <div className='font-sub-header color-cucumber text-decoration-none'>
                            <Link to="/#dcf" className="text-green text-decoration-none" onClick={handleClick}>Click Here</Link>
                        </div>
                        <p className="color-cucumber mt-5 what-we-do-text-description">
                            Whether it is a Google search, a ChatGPT prompt, or a Crypto trade, our digital carbon footprint model helps you learn about the carbon emissions of your digital lifestyle.
                        </p>
                    </div>

                    <div className="col-sm-8 col-md-4 ms-5 d-none d-xl-block">
                        <img src={CalculatorPhone} style={{ maxWidth: '450px', height: 'auto' }} alt="" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FourthPage;
