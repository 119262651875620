import { FormState, FormAction } from 'components/Dcf/dcf.model';
import { SET_FORM_ENTERTAINMENT, SET_FORM_USER, SET_FORM_PRODUCTIVITY, SET_FORM_SOCIAL, SET_FORM_BROWSING,SET_FORM_CRYPTO } from 'components/Dcf/dcf.actions';

const initialState: FormState = {
  formUser: {
    country: '',
    state: '',
    email:'',
    recaptchaToken:''
  },
  formProductivity: {
   plainemails:0,
   aimodels:0,
   emailswithattach:0,
   learningsites:0,
   spamemails:0
  },
  formSocial:{
    sms:0,
    whatsapp:0,
    tweets:0,
    zoomhours:0,
    facetime:0,
    instagram:0,
    facebook:0,
    tiktok:0
  },
  formEntertainment:{
    netflix:0,
    gaming:0,
    music:0,
    youtube:0
  },
  formBrowsing:{
    google:0,
    online:0
  },
  formCrypto:{
    transactions:0
  }
};

function dcfFormReducer(state = initialState, action: FormAction): FormState {
  switch (action.type) {
    case 'UPDATE_FIELD':
      return {
        ...state,
        [action.tab]: {
          ...state[action.tab],
          [action.field]: action.value,
        },
      };
    case SET_FORM_USER:
      return {
        ...state,
        formUser: {
          country: action.payload.country,
          state: action.payload.state,
          email: action.payload.email,
          recaptchaToken : action.payload.recaptchaToken
        },
      };
    case SET_FORM_PRODUCTIVITY:
      return {
        ...state,
        formProductivity: {
         plainemails: action.payload.plainemails,
         aimodels: action.payload.aimodels,
         emailswithattach:action.payload.emailswithattach,
        learningsites:action.payload.learningsites,
        spamemails:action.payload.spamemails
        },
      };
    case SET_FORM_SOCIAL:
      return {
        ...state,
        formSocial: {
          sms:action.payload.sms,
          whatsapp:action.payload.whatsapp,
          tweets:action.payload.tweets,
          zoomhours:action.payload.zoomhours,
          facetime:action.payload.facetime,
          instagram:action.payload.instagram,
          facebook:action.payload.facebook,
          tiktok:action.payload.tiktok
        },
      };
    case SET_FORM_ENTERTAINMENT:
        return {
          ...state,
          formEntertainment: {
            netflix:action.payload.netflix,
            youtube:action.payload.youtube,
            gaming:action.payload.gaming,
            music:action.payload.music
          },
        };
    case SET_FORM_BROWSING:
          return {
            ...state,
            formBrowsing: {
              google:action.payload.google,
              online:action.payload.online,
            },
          };
    case SET_FORM_CRYPTO:
            return {
              ...state,
              formCrypto: {
                transactions:action.payload.transactions,
              },
            };
    default:
      return state;
  }
}

export default dcfFormReducer;