import React, { useState, useRef, useEffect } from 'react';

const CountUpAnimation = ({ endValue }:any) => {
  const [isVisible, setIsVisible] = useState(false);
  const [currentValue, setCurrentValue] = useState(0);
  const targetRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          }
        });
      },
      { threshold: 0.5 } // Adjust the threshold as needed
    );

    if(targetRef.current)
    observer.observe(targetRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      let animationFrameId:any;
      const startTimestamp = performance.now();
      const duration = 300; // Animation duration in milliseconds

      const updateValue = (timestamp:any) => {
        const progress = timestamp - startTimestamp;
        const percentage = Math.min(progress / duration, 1);
        const calculatedValue = Math.floor(percentage * endValue);
        setCurrentValue(Math.max(calculatedValue, 0.01));

        if (percentage < 1) {
          // Continue the animation
          animationFrameId = window.requestAnimationFrame(updateValue);
        }
      };

      // Start the animation
      animationFrameId = window.requestAnimationFrame(updateValue);

      // Cleanup function to cancel the animation frame on component unmount
      return () => {
        window.cancelAnimationFrame(animationFrameId);
      };
    }
  }, [isVisible, endValue]);

  return <div ref={targetRef}>{isVisible && currentValue.toLocaleString()}</div>;
};

export default CountUpAnimation;
