import mobile from "assets/img/result-mobile.png";
import { DcfResultsState } from "../dcf.model";
export default function ResultMobile(resultState:DcfResultsState){
    console.log("resultData",resultState);
    const resultEmissions = resultState.resultData?.primaryResults.find(result => result.key === 'DCF')?.value.toLocaleString();
    const resultWater = resultState.resultData?.primaryResults.find(result => result.key === 'WTR')?.value.toLocaleString();
    const resultLand = resultState.resultData?.primaryResults.find(result => result.key === 'LND')?.value.toLocaleString();
    const resultWaste = resultState.resultData?.primaryResults.find(result => result.key === 'WST')?.value.toLocaleString();
    return(
        <div className='row'>
                <div className="col-lg-12 col-12 col-sm-12">
                  <div className='mobile-view'>
                  <div className="mobile-view-text">  
                    <div className="result-mobile-text result-mobile-text-emissions">{resultEmissions}</div>
                    <div className="result-mobile-text result-mobile-text-water">{resultWater}</div>
                    <div className="result-mobile-text result-mobile-text-land">{resultLand}</div>
                    <div className="result-mobile-text result-mobile-text-waste">{resultWaste}</div>
                  </div>
                  <img
                    src={mobile}
                    className="mx-auto d-block"
                    alt="DCF Mobile"
                  />   
                  
                  </div>

                </div>
        </div>
    );
}