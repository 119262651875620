import { SelectChangeEvent } from '@mui/material/Select';
import DeDropDown from 'components/common/DeDropDown/DeDropDown';
import { CustomDropdownProps } from "components/common/DeDropDown/DeDropDown.model";
import { setFormUser } from 'components/Dcf/dcf.actions';
import { DoYouKnowProps } from "components/Dcf/DoYouKnow/DoYouKnow.model";
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UsaStates } from 'usa-states';
import DoYouKnowImage from "assets/img/Present_HowBigDCF.jpg";
import DoYouKnow from '../DoYouKnow/DoYouKnow';
import styles from './FormLocation.module.css';

export default function FormLocation({value, buttonClick}:any) {
    const { country, state, email, recaptchaToken } = useSelector((state: any) => state.dcf.formUser);
    const dispatch = useDispatch();
    const requiredStateForCountry=["United States of America"];
    const [clickCssClass, setClickCssClass] = useState("validation-not-clicked");
   
    const countryList = require('country-list');
    const drdLocationCountryOptions=countryList.getNames().sort().map((option:any) => ({
        value: option,
        label: option,
      }));
    const drdLocationCountryProps:CustomDropdownProps={
        id:"dcf-drd-country",
        label:"Select country of residence",
        options:drdLocationCountryOptions,
        value:country,
        cssclass:"validation-required",
        width:300,
        onChange:(event: SelectChangeEvent) => {
            dispatch(setFormUser(event.target.value, state,email,recaptchaToken));
      }   
    }

    //const UsaStates = require('usa-states');
    var statesAndTerritories = new UsaStates(); 
    const stateObjects = statesAndTerritories.states;
    const drdLocationUsStatesOptions = stateObjects.map(state => ({ value: state.abbreviation, label: state.name }));
    const drdLocationStateProps:CustomDropdownProps={
        id:"dcf-drd-state",
        label:"Select State (if applicable)",
        options:drdLocationUsStatesOptions,
        value:state,
        width:300,
        cssclass:"validation-required",
        onChange:(event: SelectChangeEvent) => {
            dispatch(setFormUser(country, event.target.value,email,recaptchaToken));
      }   
    } 

    const startButtonClick = () => {
        SetCssClass(setClickCssClass,"validation-clicked");
        if(country===""){
            
        }else if(requiredStateForCountry.indexOf(country)>-1 && state===""){
           
        } else{
            buttonClick(value);
        }
    };

    const tileProps:DoYouKnowProps={
        imgsrc:DoYouKnowImage,
        header: (
            <div style={{ width: '200px' }}>
              <span>How big is your Digital Carbon Footprint?</span>
            </div>
          ),
          body: (
            <div style={{ width: '120px' }}>
              <span>
                We often focus on the physical things to assess carbon emissions, but what about our 'digital' lifestyle? How green is digital technology? Take your first step to learn more about your DCF.
              </span>
            </div>
          ),
         position:"left-top"
    }


    return(
        <div className={[styles.toplocation,"topform"].join(" ")}>
            <div className="row" >
                <div className="col-lg-6 col-12 col-sm-12 topform-questions"> 
                    <div className={[styles.topformlocation,clickCssClass].join(" ")}>
                        <div className={["formcontent","pt-4"].join(" ")}>
                            <div className="row">
                                <div className="col-12">
                                Let’s start with your location. This helps better understand the emissions impact on your region.
                                </div>
                            </div>
                            <div className="row pt-5">
                                <div className="col-12 text-center">
                                    <DeDropDown {...drdLocationCountryProps}></DeDropDown>
                                </div>
                            </div>
                            {requiredStateForCountry.indexOf(country)>-1 && (
                                <div className="row pt-5">
                                    <div className="col-12 text-center">
                                        <DeDropDown {...drdLocationStateProps}></DeDropDown>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="row">
                            <div className="col-12 text-end">
                                <button className="btn btn-green btn-sm mt-1" onClick={()=>{startButtonClick()}}>Start</button>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className="col-lg-6 col-12 col-sm-12">
                    <DoYouKnow {...tileProps}></DoYouKnow>
                </div>
            </div>
        </div>
    );
}

function SetCssClass(setClassName: React.Dispatch<React.SetStateAction<string>>,classname:string) {
    setClassName("");
    setTimeout(() => setClassName(classname), 150);
    setTimeout(() => setClassName(""), 400);
    setTimeout(() => setClassName(classname), 500);
}
