import React from 'react';
import styles from './FormProductivity.module.css';
import DoYouKnow from 'components/Dcf/DoYouKnow/DoYouKnow';
import DoYouKnowImage from "assets/img/Present_Chatgpt.jpg";
import { DoYouKnowProps } from "components/Dcf/DoYouKnow/DoYouKnow.model";
import DeSlider from 'components/common/DeSlider/DeSlider';
// import IconNetflix from 'assets/img/ICON_netflix.png';
import DeNumberInput from 'components/common/DeNumberInput/DeNumberInput';
import { useDispatch, useSelector } from 'react-redux';
import { CustomSliderProps } from 'components/common/DeSlider/DeSlider.model';
import { setFormProductivity } from 'components/Dcf/dcf.actions';
import { DeNumberInputProps } from 'components/common/DeNumberInput/DeNumberInput.model';

export default function FormProductivity({value,nextButtonClick,backButtonClick}:any) {
  const { plainemails,
    aimodels,
    emailswithattach,
    learningsites,
    spamemails} = useSelector((state: any) => state.dcf.formProductivity);
  const dispatch = useDispatch();
  
    const tileProps:DoYouKnowProps={
        imgsrc:DoYouKnowImage,
        header: (
            <div style={{ width: '200px' }}>
              <span>Did you know?</span>
            </div>
          ),
          body: (
            <div style={{ width: '160px' }}>
              <div>
                Artificial Intelligence underlying models have a dirty secret: they require vast amounts of energy to train and run. 
              </div>
              <div>
                Training a single AI model can emit as much carbon as five cars in their lifetimes
              </div>
              <div>
                Email is another underestimated area. It's estimated that around 300 billion emails are sent daily, and over a half of that is spam. 
              </div>
            </div>
          ),
         position:"left-top"
    }
    const sliderPlainEmails:CustomSliderProps={
      id:"vertical",
      orientation:'vertical',
      invert:true,
      min:0,
      max:1000,
      text:"Plain",
      logo:"",
      value:plainemails,
      tooltip:" emails weekly",
      onChange: (value: number) =>{
        dispatch(setFormProductivity(value, emailswithattach, spamemails, aimodels,learningsites));
      },
      onAfterChange: (value: number) =>{
        console.log("------",value);
        dispatch(setFormProductivity(value, emailswithattach, spamemails, aimodels,learningsites));
      }
    }
    const sliderEmailsWithAttachments:CustomSliderProps={
      id:"vertical",
      orientation:'vertical',
      invert:true,
      min:0,
      max:1000,
      text:"w/ Atts",
      logo:"",
      value:emailswithattach,
      tooltip:" emails weekly",
      onChange: (value: number) =>{
        dispatch(setFormProductivity(plainemails, value, spamemails, aimodels,learningsites));
      },
      onAfterChange: (value: number) =>{
        dispatch(setFormProductivity(plainemails, value, spamemails, aimodels,learningsites));
      }
    }
    const sliderSpamEmails:CustomSliderProps={
      id:"vertical",
      orientation:'vertical',
      invert:true,
      min:0,
      max:1000,
      text:"Spam",
      logo:"",
      value:spamemails,
      tooltip:" emails weekly",
      onChange: (value: number) =>{
        dispatch(setFormProductivity(plainemails, emailswithattach, value, aimodels,learningsites));
      },
      onAfterChange: (value: number) =>{
        dispatch(setFormProductivity(plainemails, emailswithattach, value, aimodels,learningsites));
      }
    }

    const numberAiModels:DeNumberInputProps={
      label:"Enter Weekly Prompts",
      value:aimodels,
      onChange:(value: number) =>{
        dispatch(setFormProductivity(plainemails, emailswithattach, spamemails, value,learningsites));
      },
      width:200,
      id:"deNumberAiModel"
    }

    const numberLearnSites:DeNumberInputProps={
      label:"Enter Weekly Hours",
      value:learningsites,
      onChange:(value: number) =>{
        dispatch(setFormProductivity(plainemails, emailswithattach, spamemails, aimodels,value));
      },
      width:200,
      id:"deNumberLearnSites"
    }


    return(
        <div  className={[styles.topproductivity,"topform"].join(" ")}>  
            <div className="row">
                <div className="col-lg-6 col-12 col-sm-12">
                    <div className={styles.topformproductivity}>
                        <div className="formcontent  pt-4">
                          <div className="row">
                            <div className="col-lg-5 col-md-5 col-sm-12">
                                <div className="row pb-2">
                                  <div className="col-4">
                                      <DeSlider {...sliderPlainEmails}></DeSlider>
                                  </div>
                                  <div className="col-5">
                                      <DeSlider {...sliderEmailsWithAttachments}></DeSlider>
                                  </div>
                                  <div className="col-3">
                                      <DeSlider {...sliderSpamEmails}></DeSlider>
                                  </div>
                                </div>
                                <div className="row pb-2">
                                  <div className="col-12 text-center">
                                      Weekly Emails
                                  </div>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-7 col-sm-12">
                              <div className="row pb-4 pt-4">
                                <div className="col-12 question-text pb-2">
                                  How many prompts used to interact with GenAI chatbots e.g. ChatGPT or Gemini
                                </div>
                                <div className="col-12 text-center">
                                  <DeNumberInput {...numberAiModels} />
                                </div>
                              </div>
                              <div className="row pb-4">
                                <div className="col-12 question-text pb-2">
                                Hours spent on upskilling or learning sites like Coursera, Canvas, etc.
                                </div>
                                <div className="col-12 text-center">
                                  <DeNumberInput {...numberLearnSites} />
                                </div>
                              </div>
                            </div>
                          </div>

                          
                          
                          {/* <DeSlider id="horizontal" orientation='horizontal' invert={false} min={0} max={150} text="YouTube" logo={IconNetflix}></DeSlider> */}
                        </div>
                        <div className="row">
                            <div className="col-6 pt-2">
                                <span className="backbutton" onClick={()=>{backButtonClick(value)}}>&lt; Back</span>
                            </div>
                            <div className="col-6 text-end">
                                 <button className="btn btn-green btn-sm mt-1" onClick={()=>{nextButtonClick(value)}}>Next</button>
                            </div>
                        </div>
                    </div> 
                   
                </div>
                <div className="col-lg-6 col-12 col-sm-12">
                    <DoYouKnow {...tileProps}></DoYouKnow>
                </div>
            </div>
        </div>
    );
}