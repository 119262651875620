export const SET_FORM_USER = 'SET_FORM_USER';
export function setFormUser(country: string, state: string, email:string, recaptchaToken:string) {
    return { type: SET_FORM_USER, payload: { country, state, email, recaptchaToken } };
}

export const SET_FORM_PRODUCTIVITY = 'SET_FORM_PRODUCTIVITY';
export function setFormProductivity(plainemails: number,
    emailswithattach: number,
    spamemails:number,
    aimodels:number,
    learningsites:number) {
    return { type: SET_FORM_PRODUCTIVITY, payload: { plainemails, emailswithattach, spamemails, aimodels,learningsites } };
}

export const SET_FORM_SOCIAL = 'SET_FORM_SOCIAL';
export function setFormSocial(sms:number,
    whatsapp:number,
    tweets:number,
    zoomhours:number,
    facetime:number,
    instagram:number,
    facebook:number,
    tiktok:number) {
    return { type: SET_FORM_SOCIAL, payload: { sms, whatsapp, tweets, zoomhours, facetime, instagram, facebook, tiktok } };
}

export const SET_FORM_ENTERTAINMENT = 'SET_FORM_ENTERTAINMENT';
export function setFormEntertainment(netflix:number,
    youtube:number,
    gaming:number,
    music:number) {
    return { type: SET_FORM_ENTERTAINMENT, payload: { netflix, youtube, gaming, music } };
}

export const SET_FORM_BROWSING = 'SET_FORM_BROWSING';
export function setFormBrowsing(
    google:number,
    online:number) {
    return { type: SET_FORM_BROWSING, payload: { google, online} };
}

export const SET_FORM_CRYPTO = 'SET_FORM_CRYPTO';
export function setFormCrypto(
    transactions:number) {
    return { type: SET_FORM_CRYPTO, payload: { transactions} };
}