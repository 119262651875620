import FirstImage from '../../../assets/img/Section2-Icon-SmokeStack.png';
import SecondImage from '../../../assets/img/Section2-Icon-eWallet.png';
import ThirdImage from '../../../assets/img/Section2-Icon-Connectedness.png';

export interface IStatistic {
  value: number;
  type: StatisticTypes;
  image: string;
  text: string;
}

export enum StatisticTypes {
  Number = 'Number',
  Percentage = 'Percentage',
}

export const StatisticsConfig: IStatistic[] = [
  {
    value: 19,
    type: StatisticTypes.Percentage,
    image: FirstImage,
    text: 'of the global GHG emissions could result from unchecked digitalization by 2040. The current ICT emissions level is double that of the scrutinized aviation sector.',
  },
  {
    value: 198,
    type: StatisticTypes.Number,
    image: SecondImage,
    text: 'million tonnes is the amount of CO2 emitted by Bitcoin since its launch. The staggering electricity consumption of Bitcoin alone is higher than that of many countries.',
  },
  {
    value: 13,
    type: StatisticTypes.Percentage,
    image: ThirdImage,
    text: 'of the global population are digitally excluded. The digital divide affects women and disadvantaged communities the most, exacerbating inequalities.',
  },
];
