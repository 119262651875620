import {StatisticsConfig} from '.';
import './SecondPage.css';
import Statistic from './Statistic';

export const SecondPage = () => {
  return (
    <section id="stats" className="page-container">
          <div className="container text-white mx-auto">
        <div className="row">
          <div className="col-12 align-items-center">
            <div className="lh-1 text-center mb-1 mb-lg-5 font-header">
              <p>
                If the digital sector were a country
                <br />
                it would be the 
                <span className="color-cucumber"> third </span> largest consumer
                of energy in the world.
              </p>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column flex-lg-row justify-content-between align-items-center align-items-lg-start flex-wrap col-12">
          {StatisticsConfig.map((stat) => (
            <Statistic
              key={stat.value}
              value={stat.value}
              type={stat.type}
              image={stat.image}
              text={stat.text}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default SecondPage;
