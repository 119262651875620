import React, { Component } from 'react';
import { FirstPage } from 'components/pages/first-page/FirstPage';
import { SecondPage } from 'components/pages/second-page/SecondPage';
import { ThirdPage } from 'components/pages/third-page/ThirdPage';
import { FourthPage } from 'components/pages/fourth-page/FourthPage';
import { FifthPage } from 'components/pages/fifth-page/FifthPage';
import {Helmet} from 'react-helmet';


export class Home extends Component {
    static displayName = Home.name;

    render() {
        return (
            <div>
                <Helmet>
                    <style>
                        {`
                            .container { 
                                width:100% !important;
                                margin-left: inherit;
                                margin-right: inherit;
                            } 
                        `}
                    </style>
                    <meta name="description" content="Digital Emissions is a non-profit organization working to reduce digital technology's environmental and social impact. Learn how to reduce your digital emissions. Make a difference today" />
                    <meta name="keywords" content="digital emissions, sustainability, environment, climate change, technology, digitalization, digital carbon footprint, sustainable it, digital carbon footprint calculator, digital sustainability, green it, digital for the planet, digital for humanity" />
                </Helmet>
                <FirstPage />
                <SecondPage />
                {<ThirdPage />}
                <FourthPage />
                <FifthPage />
            </div>

        );
    }
}
