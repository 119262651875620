'use client';
import React from 'react';
import {IStatistic, StatisticTypes} from '.';

const AnimatedNumber = React.lazy(() => import('react-animated-numbers'));

const Statistic: React.FC<IStatistic> = ({value, type, image, text}) => {
  return (
    <div className="d-flex flex-column align-items-center col-12 col-md-8 col-lg-3">
      <div className="d-flex flex-row justify-content-center justify-content-lg-start align-items-end w-100 mb-3">
        <p className="stat-header d-flex align-items-end">
          <AnimatedNumber
            animateToNumber={value}
            configs={[
              {mass: 1, tension: 130, friction: 60},
              {mass: 2, tension: 140, friction: 60},
              {mass: 3, tension: 130, friction: 60},
            ]}
          />
          {type === StatisticTypes.Percentage && (
            <span className="stat-percentage">%</span>
          )}
        </p>
        <div className="ms-4">
          <img src={image} alt="Static" className="img-fluid stat-image" />
        </div>
      </div>
      <p className="stat-text">{text}</p>
    </div>
  );
};

export default Statistic;
