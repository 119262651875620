
import { useState } from 'react';
import { FifthPageConfig, IConnect } from '.';
import './FifthPage.css';
import homeService from "components/pages/Home.Service";
import ReCAPTCHA from "react-google-recaptcha";

export const FifthPage = () => {
    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [recaptchaValue, setRecaptchaValue] = useState(null);
    const [showRecaptcha, setShowRecaptcha] = useState(false);

    const isValidEmail = (email: string) => {
        // Basic email validation using a regular expression
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };


    const subscribeButtonClick = () => {
        if (!email) {
            setErrorMessage('Please enter an email address.');
            return;
        }

        if (!isValidEmail(email)) {
            setErrorMessage('Please enter a valid email address.');
            return;
        }
        if (!recaptchaValue) {
            setErrorMessage('Please complete the reCAPTCHA.');
            return;
        }
        setErrorMessage('');

        let body: IConnect = {
            email: email,
            recaptchaToken:recaptchaValue
        }
        homeService.post('api/Home/Connect', body).then((response) => {
                console.log("--response", response);
                if (response.status === "Success") {
                    setSuccessMessage("Subscription added successfully!");
                    setEmail("");
                    setShowRecaptcha(false);
                } else {
                    setSuccessMessage("");
                    console.log(response);
                    setErrorMessage('Please try again!');
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setErrorMessage('An error occurred, please try again!');
            });
    }
    const handleEmailChange = (event: any) => {
        setSuccessMessage("");
        if(event.target.value===""){
            setRecaptchaValue(null);
        }
        setEmail(event.target.value);
        setShowRecaptcha(!!event.target.value);
    };
    const onRecaptchaChange = (value:any) => {
        console.log("****",value, "****");
        setRecaptchaValue(value);
    };
    

    return (
        <section id="contact" className="page-container">
            <div className="contact container-fluid d-flex flex-column justify-content-end">
                <div
                    className="bg-dark-transparent d-none d-lg-block mb-auto"
                    style={{ paddingTop: '60px' }}
                >
                    <div className="d-flex justify-content-md-evenly m-2">
                        {FifthPageConfig.headerLogos.map((logo, index) => {
                            return (
                                <div className="d-flex justify-content-center" key={index}>
                                    <img
                                        src={logo.image}
                                        className="logo-image justify-self-center"
                                        style={{ maxWidth: logo.maxWidth }}
                                        alt="Company Logo"
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div
                    id="footer" className="bg-dark-transparent d-flex flex-wrap flex-column flex-lg-row">
                    <div className="col-12 col-lg-2 justify-content-center px-3">
                        <div className="mt-3 color-cucumber large h5">
                            Follow us!
                        </div>
                        <div className="color-cucumber text-large"  style={{ marginLeft: '-15px' }} >
                           
                            {FifthPageConfig.footerSocialMedia.map((socialMedia, index) => {
                                return (
                                    <div key={index} className='float-start'>
                                        <a
                                            href={socialMedia.link}
                                            className="color-cucumber"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <i className={socialMedia.className}></i>
                                        </a>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="col-12 col-lg-3 px-3">
                    <div className="mt-3 color-cucumber large h5">
                            Support us
                    </div>
                    <div className="mt-2">
                                <p className="small text-muted">
                                    Digital Emissions, Inc., is a US 501(c)(3) nonprofit.
                                    <br />
                                    Boston, Massachusetts.
                                    <span className="color-cucumber"> EIN 92-2710452</span>
                                </p>
                    </div>
                    <div>
                    <a href='https://www.paypal.com/donate/?hosted_button_id=VL3W5GL2CYY7U' target='_blank' rel="noreferrer" className="color-cucumber me-4 text-decoration-none">Donate</a>
                    <a href='mailto:hello@digitalemissions.org' className="color-cucumber text-decoration-none">Volunteer</a>
                    </div>
                    </div>
                    <div className="col-12 col-lg-3 text-white px-3">
                        <div className="d-flex flex-column mt-3 text-white justify-content-start">
                            <label htmlFor="contact-us" className="color-cucumber large h5">
                                Connect with us!
                            </label>
                            <div
                                className="btn-group"
                                role="group"
                                aria-label="Text and Button Group"
                            >
                                <input
                                    type="text"
                                    className="form-control email-input"
                                    placeholder="Enter email address..."
                                    value={email}
                                    onChange={handleEmailChange}
                                />
                                
                                <button type="button" className="btn btn-cucumber subscribe-button" onClick={() => { subscribeButtonClick() }}>
                                    Subscribe
                                </button>
                            </div>
                           
                            {showRecaptcha &&  <div className='reCaptchaContainer'><ReCAPTCHA
                sitekey="6LcY8m8pAAAAAB0lk190FTLS3aM6VOcC6_eoCMP2"
                onChange={onRecaptchaChange} theme="dark" style={{ width: '350px', height: 'auto' }} 
            /> 
                            </div>}
                            {errorMessage && <p className="text-danger small">{errorMessage}</p>}
                            {successMessage && <p className="color-cucumber small">{successMessage}</p>}

                            
                        </div>
                    </div>
                    <div className="col-12 col-lg-3  offset-lg-1 text-white px-3">
                        <div className="d-flex flex-column mt-3 text-white justify-content-start">
                            <p className="color-cucumber large h5">Drop us a line</p>
                            <p className="small">Looking forward to hear from you <br /> <a href='mailto:hello@digitalemissions.org' className="color-cucumber">hello@digitalemissions.org</a> <br /><br /> &copy; Digital Emissions 2024. All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
};



export default FifthPage;
