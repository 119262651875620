const BASE_URL = "/";
//const csrfToken = Document.

async function get(url:string) {
    const response = await fetch(`${BASE_URL}${url}`);
    const data = await response.json();
    return data;
}
async function post(url:any, body:any) {
    const response = await fetch(`${BASE_URL}${url}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    const data = await response.json();
    return data;
  }
const homeService = {
    get,
    post
  };
  
  export default homeService;