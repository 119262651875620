import React from 'react';
import styles from './FormEntertainment.module.css';
import DoYouKnow from 'components/Dcf/DoYouKnow/DoYouKnow';
import DoYouKnowImage from "assets/img/Present_Entertinment.jpg";
import { DoYouKnowProps } from "components/Dcf/DoYouKnow/DoYouKnow.model";
import { useDispatch, useSelector } from 'react-redux';
import { CustomSliderProps } from 'components/common/DeSlider/DeSlider.model';
import { setFormEntertainment } from '../dcf.actions';
import IconNetflix from 'assets/img/ICON_netflix.png';
import IconYoutube from 'assets/img/ICON_youtube.png';
import IconGaming from 'assets/img/ICON_gaming.png';
import IconMusic from 'assets/img/ICON_spotify.png';
import DeSlider from 'components/common/DeSlider/DeSlider';

export default function FormEntertainment({value,nextButtonClick,backButtonClick}:any) {
  const { netflix,
  youtube,
  gaming,
  music} = useSelector((state: any) => state.dcf.formEntertainment);
  const dispatch = useDispatch();
  
    const tileProps:DoYouKnowProps={
        imgsrc:DoYouKnowImage,
        header: (
            <div style={{ width: '200px' }}>
              <span>Did you know?</span>
            </div>
          ),
          body: (
            <div style={{ width: '160px' }}>
              <div>
                The daily carbon footprint of YouTube is equivalent to traveling the moon and back more than 30 times. 
              </div>
            </div>
          ),
         position:"left-top"
    }
    const sliderNetflix:CustomSliderProps={
      id:"horizontalNetflix",
      orientation:'horizontal',
      invert:false,
      min:0,
      max:100,
      text:"Video Streaming e.g. Netflix",
      logo:IconNetflix,
      value:netflix,
      tooltip:" hours weekly",
      onChange: (value: number) =>{
        dispatch(setFormEntertainment(value,youtube,gaming,music));
      },
      onAfterChange: (value: number) =>{
        dispatch(setFormEntertainment(value,youtube,gaming,music));
      }
    }
    const sliderYoutube:CustomSliderProps={
      id:"horizontalNetflix",
      orientation:'horizontal',
      invert:false,
      min:0,
      max:100,
      text:"YouTube",
      logo:IconYoutube,
      value:youtube,
      tooltip:" hours weekly",
      onChange: (value: number) =>{
        dispatch(setFormEntertainment(netflix,value,gaming,music));
      },
      onAfterChange: (value: number) =>{
        dispatch(setFormEntertainment(netflix,value,gaming,music));
      }
    }
    const sliderGaming:CustomSliderProps={
      id:"horizontalNetflix",
      orientation:'horizontal',
      invert:false,
      min:0,
      max:100,
      text:"Gaming",
      logo:IconGaming,
      value:gaming,
      tooltip:" hours weekly",
      onChange: (value: number) =>{
        dispatch(setFormEntertainment(netflix,youtube,value,music));
      },
      onAfterChange: (value: number) =>{
        dispatch(setFormEntertainment(netflix,youtube,value,music));
      }
    }
    const sliderMusic:CustomSliderProps={
      id:"horizontalNetflix",
      orientation:'horizontal',
      invert:false,
      min:0,
      max:100,
      text:"Music Streaming e.g. Spotify",
      logo:IconMusic,
      value:music,
      tooltip:" hours weekly",
      onChange: (value: number) =>{
        dispatch(setFormEntertainment(netflix,youtube,gaming,value));
      },
      onAfterChange: (value: number) =>{
        dispatch(setFormEntertainment(netflix,youtube,gaming,value));
      }
    }
    return(
        <div className={[styles.topentertainment,"topform"].join(" ")}>
            
            <div className="row">
                <div className="col-lg-6 col-12 col-sm-12"> 
                  <div className={styles.topformentertainment}>
                    <div className="formcontent  pt-4 pb-4">
                      <div className="row">
                        <div className="col-12">
                          <div className="row mb-4">
                            <div className="col-12">
                            <DeSlider {...sliderNetflix}></DeSlider>
                            </div>
                          </div>
                          <div className="row mb-4">
                            <div className="col-12">
                            <DeSlider {...sliderYoutube}></DeSlider>
                            </div>
                          </div>
                          <div className="row mb-4">
                            <div className="col-12">
                            <DeSlider {...sliderGaming}></DeSlider>
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-12">
                            <DeSlider {...sliderMusic}></DeSlider>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                            <div className="col-6 pt-2">
                                <span className="backbutton" onClick={()=>{backButtonClick(value)}}>&lt; Back</span>
                            </div>
                            <div className="col-6 text-end">
                                 <button className="btn btn-green btn-sm mt-1" onClick={()=>{nextButtonClick(value)}}>Next</button>
                            </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-12 col-sm-12">
                    <DoYouKnow {...tileProps}></DoYouKnow>
                </div>
            </div>
        </div>
    );
}