import React, { useEffect, useState } from 'react';
import styles from './Dcf.module.css';
import { Helmet } from 'react-helmet';
import LinearProgress from '@mui/material/LinearProgress';
import background from "../../assets/img/CodeBackdrop.png";
import banner from "../../assets/img/dcf_results_banner.svg";
import { Container } from 'reactstrap';
import ResultFlow from "./ResultFlow/ResultFlow";
import ResultMobile from "./ResultMobile/ResultMobile";
import dcfService from "components/Dcf/dcf.service";
import { DcfResultData } from "./dcf.model";
import coffee from "../../assets/img/coffee.png";

const DcfResults = () => {
  const [resultData, setResultData] = useState<DcfResultData | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Read query string params
        const queryParams = new URLSearchParams(window.location.search);
        const paramValue = queryParams.get('UID');

        if (paramValue) {
          const response = await dcfService.get(`api/DCF/GetDCFResults?UID=${paramValue}`);
          console.log("response=",response);
          setResultData(response);

          if (response && response.status === "Error") {
            // Redirect if invalid response to dcf page
          }
        }
      } catch (error) {
        console.error('API call error', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures useEffect runs only once, equivalent to componentDidMount

  const shouldRenderComponents = resultData && resultData?.status !== "Error";

  return (
    <div className={styles.Dcf}>
      <Helmet>
        <style>
          {`
            body { 
              background-image: linear-gradient(rgba(0,0,0,.8), rgba(0,0,0,.8)),url(${background});
              background-position: center center;
              background-attachment: fixed;
              background-repeat: no-repeat; background-size: cover;
            } 
          `}
        </style>
      </Helmet>
      {loading && <LinearProgress sx={{
      '& .MuiLinearProgress-bar1Indeterminate': { backgroundColor: '#4db23d' },
      '& .MuiLinearProgress-bar2Indeterminate': { backgroundColor: '#fff' },
    }} color="primary" />}
      {shouldRenderComponents && (
        <Container>
          <div className="row">
            <div className="col-lg-12 col-12 col-sm-12">
              <img
                src={banner}
                className="mx-auto d-block result-banner"
                alt="DCF Banner"
              />
            </div>
          </div>
          <div className='pb-5'>
            <ResultMobile resultData={resultData}></ResultMobile>
          </div>
          <div className="row">
            <div className="col-lg-12 col-12 col-sm-12">
              <div className='equ-text text-center'>
                That's equivalent to <span className='color-cucumber'>...</span>
              </div>
            </div>
          </div>
          <div className="pt-5">
            <ResultFlow resultData={resultData}></ResultFlow>
          </div>
          <div className="coffee-container">
            <div className="coffee-content">
              <img src={coffee}  className="coffee-image" alt='coffee'/>
              <div className="text-container">
                <div>Support more projects like this.</div>
                <div>Buy the developer a coffee.</div>
                <div className="last-div-class"><a href='https://www.paypal.com/donate/?hosted_button_id=VL3W5GL2CYY7U' target='_blank' rel="noreferrer">Donate</a> Today.</div>
              </div>
            </div>
          </div>
        </Container>
      )}
    </div>
  );
};

export default DcfResults;
