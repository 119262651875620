import { Counter } from "./components/Counter";
import { FetchData } from "./components/FetchData";
import { Home } from "./components/Home";
import { Dcf } from "./components/Dcf/Dcf";
import DcfResults  from "components/Dcf/DcfResults";

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/counter',
    element: <Counter />
  },
  {
    path: '/fetch-data',
    element: <FetchData />
  },
  {
    path: '/dcf',
    element: <Dcf />
  },
  {
    path: '/dcfresults',
    element: <DcfResults />
  }
  
];

export default AppRoutes;
