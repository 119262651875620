import React, { Component } from 'react';
import  NavMenu  from './NavMenu';
import { ScrollToTop } from 'components/layout/ScrollToTop/ScrollToTop';


export class Layout extends Component {
    static displayName = Layout.name;
    render() {
        return (
            <div className="main-container" id='main-container'>
                    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css"></link>
                <NavMenu />
                
                  {this.props.children}
                  <ScrollToTop />
                
               </div>
        );
    }
}
