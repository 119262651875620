'use client';
import {IFlipCard} from '.';
import './FlipCard.css';
import { useState} from 'react';

const FlipCard: React.FC<IFlipCard> = ({image, frontText, backText}) => {
    const [flipped, setFlipped] = useState<boolean>(false);
    // useEffect(() => {
    //   const seconds = 5;
    //   const interval = setInterval(() => {
    //     setFlipped(!flipped);
    //   }, seconds * 1000);

    //   return () => clearInterval(interval);
    // }, [flipped]);

    return (
        <div
            className="flip-card-container"
            style={{ cursor: 'pointer' }}
            onClick={() => setFlipped(!flipped)}
        >
            <div className={`flip-card-inner ${flipped ? 'flipped' : ''}`}>
                <div className="flip-card-front">
                    <div className="card card-green align-items-center pt-3">
                        <img src={image} className="icon" alt="" />
                        <p className="text-center text-white p-2">{frontText}</p>
                    </div>
                </div>
                <div className="flip-card-back">
                    <div className="card card-green align-items-center justify-content-center pt-3">
                        <p className="text-center text-white p-2">{backText}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FlipCard;
