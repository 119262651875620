import IEEELogo from '../../../assets/img/Section5-IEEE.png'; 
import GreenSoftware from '../../../assets/img/green-software.png';
import CodersLogo from '../../../assets/img/Section5-Coders.png';
import UNLogo from '../../../assets/img/Section5-UN.png';

interface IHeaderLogo {
    image: string;
  maxWidth: string;
}

interface IFooterSocialMedia {
  name: string;
  className: string;
  link: string;
}

interface IFooterLink {
  text: string;
  link: string;
}
export interface IConnect{
  email : string
  recaptchaToken:string
}

export interface IFifthPageConfig {
  headerLogos: IHeaderLogo[];
  footerSocialMedia: IFooterSocialMedia[];
  footerLinks: IFooterLink[];
}

export const FifthPageConfig: IFifthPageConfig = {
  headerLogos: [
    {image: IEEELogo, maxWidth: '170px'},
    {image: GreenSoftware, maxWidth: '170px'},
    {image: CodersLogo, maxWidth: '170px'},
    {image: UNLogo, maxWidth: '170px'},
  ],
  footerSocialMedia: [
    {
      name: 'Facebook',
      className: 'fab fa-facebook m-3 fs-3',
      link: 'https://www.facebook.com/DigitalCO2e/',
    },
    {
      name: 'Twitter',
      className: 'fab fa-x-twitter m-3 fs-3',
      link: 'https://twitter.com/digitalCO2e/',
    },
    {
      name: 'Instagram',
      className: 'fab fa-instagram m-3 fs-3',
      link: 'https://www.instagram.com/DigitalCO2e/',
    },
    {name: 'LinkedIn', className: 'fab fa-linkedin m-3 fs-3', link: 'https://www.linkedin.com/company/digitalco2e/'},
  ],
  footerLinks: [
    // {text: 'Home', link: '#home'},
    {text: 'What we do', link: '#products'},
      { text: 'DCF Calculator', link: '#dcf'},
      { text: 'Donate', link: 'https://www.paypal.com/donate/?hosted_button_id=VL3W5GL2CYY7U'},
  ],
};
