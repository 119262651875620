import React from 'react';
import { useSelector } from 'react-redux';
import styles from './dcftabs.module.css';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import IconProductivity from "assets/icons/7727554_production_development_gear_person_productivity_icon (1).png";
import IconSocial from "assets/icons/2006793_social media_communication_messaging_icon.png";
import IconEntertainment from "assets/icons/6611332_and_cinema_entertainment_movie_movies_icon.png";
import IconBrowsing from "assets/icons/4023884_internet_mobile_smartphone_icon.png";
import IconCrypto from "assets/icons/8134632_bitcoin_token_crypto_icon.png";

import FormLocation from "../FormLocation/FormLocation";
import FormProductivity from "../FormProductivity/FormProductivity";
import FormSocial from "../FormSocial/FormSocial";
import FormEntertainment from "../FormEntertainment/FormEntertainment";
import FormBrowsing from "../FormBrowsing/FormBrowsing";
import FormCrypto from "../FormCrypto/FormCrypto";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function Dcftabs() {
  const [value, setValue] = React.useState(0);
  // const [tabsWidth, setTabsWidth]= React.useState(0);
  // const tabsRef =useRef<any>(null);


  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const tabButtonClick = (from:any)=>{
    console.log(from);

    if(value<5){
      setValue(value+1);
    }
  }
  const tabBackButtonClick = (from:any)=>{
    console.log(from);

    if(value>0){
      setValue(value-1);
    }
  }
  const tabLocation = useSelector((state:any) => state.tabLocation);
  console.log(tabLocation);
  return (
    <div className={styles.Dcftabs}>
    <Box sx={{  padding:'0px' }}>
      <Tabs  TabIndicatorProps={{style: {background:'#4db33d'}}} value={value} onChange={handleChange} aria-label="icon label tabs example" variant={'standard'} >
            <Tab className={[styles.tab,"d-none"].join(' ')} icon={<img alt="Location" className={styles.tabicon} src={IconProductivity} />} label={<span className={styles.tabtext}>Location</span>} />
            <Tab className={styles.tab} icon={<img alt="Productivity" className={styles.tabicon} src={IconProductivity} />} label={<span className={styles.tabtext}>Productivity</span>} />
            <Tab className={styles.tab} icon={<img alt="Social" className={styles.tabicon} src={IconSocial} />} label={<span className={styles.tabtext}>Social</span>} />
            <Tab className={styles.tab} icon={<img alt="Entertainment" className={styles.tabicon} src={IconEntertainment} />} label={<span className={styles.tabtext}>Entertainment</span>} />
            <Tab className={styles.tab} icon={<img alt="Browsing" className={styles.tabicon} src={IconBrowsing} />} label={<span className={styles.tabtext}>Browsing</span>} />
            <Tab className={styles.tab} icon={<img alt="Crypto" className={styles.tabicon} src={IconCrypto} />} label={<span className={styles.tabtext}>Crypto</span>}  />
      </Tabs>
    </Box>
    <TabPanel value={value} index={0}>
      <FormLocation value={value} buttonClick={tabButtonClick}></FormLocation>
    </TabPanel>
    <TabPanel value={value} index={1}>
      <FormProductivity value={value} nextButtonClick={tabButtonClick} backButtonClick={tabBackButtonClick}></FormProductivity>
    </TabPanel>
    <TabPanel value={value} index={2}>
      <FormSocial value={value} nextButtonClick={tabButtonClick} backButtonClick={tabBackButtonClick}></FormSocial>
    </TabPanel>
    <TabPanel value={value} index={3}>
      <FormEntertainment value={value} nextButtonClick={tabButtonClick} backButtonClick={tabBackButtonClick}></FormEntertainment>
    </TabPanel>
    <TabPanel value={value} index={4}>
      <FormBrowsing value={value} nextButtonClick={tabButtonClick} backButtonClick={tabBackButtonClick}></FormBrowsing>
    </TabPanel>
    <TabPanel value={value} index={5}>
      <FormCrypto value={value} backButtonClick={tabBackButtonClick}></FormCrypto>
    </TabPanel>
  </div>
  );
}

