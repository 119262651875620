import { CustomSliderProps } from "components/common/DeSlider/DeSlider.model";
import ReactSlider from "react-slider";
import styles from './DeSlider.module.css';
import Tooltip from "@mui/material/Tooltip";

export default function DeSlider(sliderProps:CustomSliderProps) {
    const className = `${sliderProps.orientation}-slider`;
    //const [sliderValue, setSliderValue] = useState(sliderProps.min);

    const handleSliderChange = (newValues:number) => {
        sliderProps.onChange(newValues);
    };

    const handleSliderAfterChange = (newValues:number) => {
        console.log("Slider value changed to:", newValues);
        sliderProps.onAfterChange(newValues);
    };
    return(
    <div id={sliderProps.id}>
        {sliderProps.orientation==="horizontal" && (<div className={[styles.horizontal,"row"].join(" ")}>
            <div className={[styles.slidertext,"col-3","align-self-center"].join(" ")}>{sliderProps.text}</div>
           {sliderProps.logo!=="" && ( <div className={[styles.sliderlogo,"col-1","justify-content-center" ,"align-self-center"].join(" ")}><img src={sliderProps.logo} alt={sliderProps.text}/></div>)}
            <div className="col justify-content-center align-self-center">
            <ReactSlider
                    value={sliderProps.value}
                    className={className}
                    thumbClassName="example-thumb"
                    trackClassName="example-track"
                    renderThumb={(props, state) => (
                        <Tooltip title={`${state.valueNow}${sliderProps.tooltip}`} placement="bottom"  enterTouchDelay={0} arrow>
                          <div {...props}>{state.valueNow}</div>
                        </Tooltip>
                      )}
                    orientation={sliderProps.orientation}
                    invert={sliderProps.invert ? true : undefined}
                    min={sliderProps.min}
                    max={sliderProps.max}
                    onChange={handleSliderChange}
                    onAfterChange={handleSliderAfterChange}
                />
            </div>
        </div>)}

        {sliderProps.orientation==="vertical" && (
        <div className={styles.vertical}>
            <div  className={styles.verticalslider}>
            <ReactSlider
                    value={sliderProps.value}
                    className={[className,"ms-2","mx-auto"].join(" ")}
                    thumbClassName="example-thumb"
                    trackClassName="example-track"
                    renderThumb={(props, state) => (
                        <Tooltip title={`${state.valueNow}${sliderProps.tooltip}`} placement="bottom" enterTouchDelay={0} arrow>
                          <div {...props}>{state.valueNow}</div>
                        </Tooltip>
                      )}
                    orientation={sliderProps.orientation}
                    invert={sliderProps.invert ? true : undefined}
                    min={sliderProps.min}
                    max={sliderProps.max}
                    onChange={handleSliderChange}
                    onAfterChange={handleSliderAfterChange}
                    
                />
            </div>
            {sliderProps.logo!=="" && (<div  className={[styles.sliderlogo,"text-center mt-2"].join(" ")}><img src={sliderProps.logo} alt={sliderProps.text}/></div>)}
            <div  className={[styles.slidertext,"text-center","pt-2"].join(" ")}>{sliderProps.text}</div>
        </div>
        )}
       
    </div>);
}