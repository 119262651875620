import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from '@mui/material';
import { setFormUser } from 'components/Dcf/dcf.actions';
import { useDispatch, useSelector } from 'react-redux';
import dcfService from "components/Dcf/dcf.service";
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import styles from './EmailInput.module.css';
import background from "assets/img/CodeBackdrop.png";
import ReCAPTCHA from 'react-google-recaptcha';

const EmailInput = ({ open, onClose }:any) => {
    const navigate = useNavigate();
    const formState = useSelector((state:any) => state.dcf);
    const { country, state, email, recaptchaToken } = useSelector((state: any) => state.dcf.formUser);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [showRecaptcha, setShowRecaptcha] = useState(false);
    
 // const [email, setEmail] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [emailError, setEmailError] = useState(false);

  const handleClose = () => {
    dispatch(setFormUser(country, state,'','')); // Clear email on close
    onClose();
  };

  const handleSave = () => {
    // Perform email validation

    if (!validateEmail(email)) {
      setErrorMsg('Please enter a valid email');
      setEmailError(true);
      return;
    }
    if (recaptchaToken==='') {
      setErrorMsg('Please complete the reCAPTCHA.');
      setEmailError(true);
      return;
    }
    setEmailError(false);
      console.log('Email:', email);
      setLoading(true);
      open=false;
      dcfService.post("api/DCF/SubmitForm",formState).then((response) => {
        console.log("--response",response);
        setLoading(false);
        if(response.status==="Success"){
          navigate('/dcfresults?UID='+response.message);
        }else{
          setErrorMsg(response.message);
          setEmailError(true);
        }
      })
      .catch((error) => {
        setErrorMsg('An error occurred, please try again!');
        setEmailError(true);
        setLoading(false);
        console.error("Error fetching data:", error);
      });
   
  };
  const validateEmail = (email:string) => {
    // Basic email validation using regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleRecaptchaChange = (value: any) => {
    dispatch(setFormUser(country, state, email, value));
    setShowRecaptcha(!!email);
  };
  const handleEmailChange = (event: any) => {
    setErrorMsg("");
    if(event.target.value===""){
      dispatch(setFormUser(country, state,"",""));
    }else{
      dispatch(setFormUser(country, state,event.target.value,recaptchaToken))
    } 
    setShowRecaptcha(!!event.target.value);
  };

  const paperStyle={backgroundImage:`linear-gradient(rgba(0,0,0,.8), rgba(0,0,0,.8)), url(${background})`, backgroundColor:'black', backgroundPosition:'center center', backgroundAttachment:'fixed', backgroundRepeat: 'no-repeat',backgroundSize:'cover', boxShadow:'0 0 5px rgba(255, 255, 255, 1)' };
  return (
    <div>
    {loading ? <div className="overlay"> <div className="footprint"></div></div>:
    
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs" PaperProps={{ style: paperStyle }}>
      <DialogTitle style={{ color: '#fff',fontFamily: 'inherit' }}>Enter your Email</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
        
          type="email"
          fullWidth
          value={email}
          onChange={handleEmailChange}
          error={emailError}
          helperText={emailError ? errorMsg : ''}
          variant="outlined"
          InputProps={{
            className: styles.textfield,
            classes: {
              notchedOutline: styles.notchedoutline
            }
          }}
          InputLabelProps={{
            shrink: false,
            classes: {
              shrink: styles.shrink, // Map the shrink class to the CSS class name 
            },
          }}
        />
       
                
                {showRecaptcha && <ReCAPTCHA
                    sitekey="6LcY8m8pAAAAAB0lk190FTLS3aM6VOcC6_eoCMP2"
                    onChange={handleRecaptchaChange} theme="dark"
                />}
      </DialogContent>
      <DialogActions style={{ justifyContent: 'flex-start' }}>
        <button className="btn btn-green btn-sm mb-3 me-3 ms-3" onClick={handleSave} >{loading ? <CircularProgress size={24} /> : 'View Results'}</button>
      </DialogActions>
  
      
    </Dialog>
}
    </div>
  );
};

export default EmailInput;
