import { FlipCardsConfig } from '.';
import FlipCard from './FlipCard';
import './ThirdPage.css';

export const ThirdPage = () => {
    return (
        <section id="products" className="page-container">
            <div className="container-fluid products d-flex justify-content-center align-items-center">
                <div className="row d-flex justify-content-center mt-1">
                    <div className="col-lg-6 order-lg-1 col-12 order-2 d-flex align-items-start flex-xxl-row flex-column-reverse justify-content-center justify-content-md-around">
                        <div className="row col-12 col-lg-6 mx-auto mx-lg-0 justify-content-sm-center justify-content-lg-start">
                            {FlipCardsConfig.map((card) => {
                                return (
                                    <div
                                        className="col-xxl-8 col-lg-11 col-sm-8 col-12 mt-2 mt-lg-1"
                                        key={card.frontText}
                                    >
                                        <FlipCard
                                            image={card.image}
                                            frontText={card.frontText}
                                            backText={card.backText}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                        {/*<div className="col-lg-6 col-12 d-flex align-items-start justify-content-center justify-content-lg-start">*/}
                        {/*  <div className="font-header lh-1 text-white mt-1 mb-4 ps-lg-2 ps-xxl-0">*/}
                        {/*    What we do*/}
                        {/*  </div>*/}
                        {/*</div>*/}
                    </div>
                    <div className="col-lg-6 order-lg-3 col-12 order-3 circles-container d-none d-lg-block">
                        <div className="float-end">
                            <div className="circles mx-auto"></div>
                            <p className="text-white  text-center mt-3 d-none d-lg-block">
                                We are working tirelessly to make the digital sector <br />
                                more socially and environmentally
                                <br />
                                responsible.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ThirdPage;
