

import React, {  useEffect } from 'react';
import './ScrollToTop.css';

export const ScrollToTop = () => {

    useEffect(() => {
        //Get the button
        let mybutton = document.getElementById('btn-back-to-top');
        let mainContainer = document.getElementById('main-container');
       

        // When the user scrolls down 20px from the top of the document, show the button
        if (mainContainer !== null) {
            mainContainer.onscroll = function () {
                scrollFunction();
            };
            console.log(mainContainer.onscroll);
           
        }

        function scrollFunction() {
            if (mybutton !== null && mainContainer !== null) {
                if (mainContainer.scrollTop > 100) {
                    mybutton.style.display = 'block';
                } else {
                    mybutton.style.display = 'none';
                }
            }
        }
        // When the user clicks on the button, scroll to the top of the document
        if (mybutton !== null) {
            mybutton.addEventListener('click', backToTop);
        }

        function backToTop() {
            if (mainContainer !== null) {
                mainContainer.scrollTop = 0;
            }
        }
    }, []); 

    return (
        <button
            type="button"
            className="btn btn-floating btn-lg"
            id="btn-back-to-top"
        >
            <i className="fas fa-arrow-up"></i>
        </button>
    );
};






