import React from 'react';
import styles from './FormBrowsing.module.css';
import DoYouKnow from 'components/Dcf/DoYouKnow/DoYouKnow';
import DoYouKnowImage from "assets/img/Present_Browsing.jpg";
import { DoYouKnowProps } from "components/Dcf/DoYouKnow/DoYouKnow.model";
import { useDispatch, useSelector } from 'react-redux';
import { DeNumberInputProps } from 'components/common/DeNumberInput/DeNumberInput.model';
import { setFormBrowsing } from '../dcf.actions';
import DeNumberInput from 'components/common/DeNumberInput/DeNumberInput';

export default function FormBrowsing({value,nextButtonClick,backButtonClick}:any) {
    const { google,
    online} = useSelector((state: any) => state.dcf.formBrowsing);
    const dispatch = useDispatch();
    const tileProps:DoYouKnowProps={
        imgsrc:DoYouKnowImage,
        header: (
            <div style={{ width: '200px' }}>
              <span>Did you know?</span>
            </div>
          ),
          body: (
            <div style={{ width: '160px' }}>
              <div>
                Being the most visited website on the Internet, Google process more than 8 billion searches daily with each emitting around 0.2 grams of CO2. 
              </div>
            </div>
          ),
         position:"left-top"
    }
    const numberGoogle:DeNumberInputProps={
      label:"Enter Weekly Searches",
      value:google,
      onChange:(value: number) =>{
        dispatch(setFormBrowsing(value,online));
      },
      width:245,
      id:"deNumberGoogle"
    }
    const numberOnline:DeNumberInputProps={
      label:"Enter Weekly Browsing Time",
      value:online,
      onChange:(value: number) =>{
        dispatch(setFormBrowsing(google,value));
      },
      width:245,
      id:"deNumberGoogle"
    }
    return(
        <div className={[styles.topbrowsing,"topform"].join(" ")}>
            <div className="row">
                <div className="col-lg-6 col-12 col-sm-12"> 
                  <div className={styles.topformbrowsing}>
                    <div className="formcontent  pt-4 pb-4">
                      <div className="row">
                        <div className="col-12">
                          <div className="row">
                            <div className="col-12 text-center mb-3 mt-4">
                              How many Google searches you do a week?
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 text-center mb-5">
                            <DeNumberInput {...numberGoogle} />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 text-center mb-3">
                              How many hours do you average a week on Online Time other than asked categories? 
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 text-center mb-4">
                            <DeNumberInput {...numberOnline} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                            <div className="col-6 pt-2">
                                <span className="backbutton" onClick={()=>{backButtonClick(value)}}>&lt; Back</span>
                            </div>
                            <div className="col-6 text-end">
                                 <button className="btn btn-green btn-sm mt-1" onClick={()=>{nextButtonClick(value)}}>Next</button>
                            </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-12 col-sm-12">
                    <DoYouKnow {...tileProps}></DoYouKnow>
                </div>
            </div>
        </div>
    );
}