import { combineReducers, configureStore } from '@reduxjs/toolkit';
import dcfFormReducer from 'components/Dcf/dcf.formreducer';


const rootReducer = combineReducers({
  dcf: dcfFormReducer,
});

const store = configureStore({
  reducer: rootReducer
});

export default store;