import ResultEquivalentTile from "components/Dcf/ResultEquivalentTile/ResultEquivalentTile"
import { ResultEquivalentProps } from "../ResultEquivalentTile/ResultEquivalentTile.model";
import ResultTileConnector from "components/Dcf/ResultTileConnector/ResultTileConnector";
import imgGreen1 from "assets/img/Result-Green-1.svg";
import imgGreen2 from "assets/img/Result-Green-2.svg";
import imgGreen3 from "assets/img/Result-Green-3.svg";
import imgGreen4 from "assets/img/Result-Green-4.svg";
import {ResultConnectorProps} from "../ResultTileConnector/ResultTileConnector.model";
import car from "assets/img/AnimeCar.gif";
import home from "assets/img/AnimeHome.gif"
import bin from "assets/img/AnimeBin.gif";
import light from "assets/img/AnimeLight.gif";
import tree from "assets/img/AnimeTree.gif";
import phone from "assets/img/AnimePhone.gif";
import bath from "assets/img/AnimeBath.gif";
import coal from "assets/img/AnimeCoal.gif";
import { DcfResultsState } from "../dcf.model";
export default function ResultFlow(resultState:DcfResultsState){
    const tileCAR=resultState.resultData?.secondaryResults.find(result => result.key === 'CAR');
    const tile1:ResultEquivalentProps={
        imggreen:imgGreen1,
        value:"",
        description:"",
        greenStyleClass:"green-style-1",
        alignClass:"result-tile-left",
        tileStyle:{
            rotate:'-5deg'
        },
        imganimated:car,
        tilenum:tileCAR?.value,
        tiledesc:tileCAR?.description
    }
    const tileHOM=resultState.resultData?.secondaryResults.find(result => result.key === 'HOM');
    const tile2:ResultEquivalentProps={
        imggreen:imgGreen2,
        value:"",
        description:"",
        greenStyleClass:"green-style-2",
        alignClass:"result-tile-right",
        tileStyle:{
            rotate:'0deg'
        },
        imganimated:home,
        tilenum:tileHOM?.value,
        tiledesc:tileHOM?.description
    }
    const connect1:ResultConnectorProps={
        style:{}
    }
    const connect2:ResultConnectorProps={
        style:{
            transform:'scaleX(-1)',
            left:'-100px'
        }
    }
    const tileCOL=resultState.resultData?.secondaryResults.find(result => result.key === 'COL');
    const tile3:ResultEquivalentProps={
        imggreen:imgGreen3,
        value:"",
        description:"",
        greenStyleClass:"green-style-3",
        alignClass:"result-tile-left",
        tileStyle:{
            rotate:'0deg'
        },
        imganimated:coal,
        tilenum:tileCOL?.value,
        tiledesc:tileCOL?.description
    }
    const tileBIN=resultState.resultData?.secondaryResults.find(result => result.key === 'BIN');
    const tile4:ResultEquivalentProps={
        imggreen:imgGreen4,
        value:"",
        description:"",
        greenStyleClass:"green-style-4",
        alignClass:"result-tile-right",
        tileStyle:{
            rotate:'0deg'
        },
        imganimated:bin,
        tilenum:tileBIN?.value,
        tiledesc:tileBIN?.description
    }
    const tileLED=resultState.resultData?.secondaryResults.find(result => result.key === 'LED');
    const tile5:ResultEquivalentProps={
        imggreen:imgGreen1,
        value:"",
        description:"",
        greenStyleClass:"green-style-1",
        alignClass:"result-tile-left",
        tileStyle:{
            rotate:'0deg'
        },
        imganimated:light,
        tilenum:tileLED?.value,
        tiledesc:tileLED?.description
    }
    const tileTRE=resultState.resultData?.secondaryResults.find(result => result.key === 'TRE');
    const tile6:ResultEquivalentProps={
        imggreen:imgGreen2,
        value:"",
        description:"",
        greenStyleClass:"green-style-2",
        alignClass:"result-tile-right",
        tileStyle:{
            rotate:'0deg'
        },
        imganimated:tree,
        tilenum:tileTRE?.value,
        tiledesc:tileTRE?.description
    }
    const tilePON = resultState.resultData?.secondaryResults.find(result => result.key === 'PON');
    const tile7:ResultEquivalentProps={
        imggreen:imgGreen3,
        value:"",
        description:"",
        greenStyleClass:"green-style-3",
        alignClass:"result-tile-left",
        tileStyle:{
            rotate:'0deg'
        },
        imganimated:phone,
        tilenum:tilePON?.value,
        tiledesc:tilePON?.description
    }
    const tileBAT=resultState.resultData?.secondaryResults.find(result => result.key === 'BAT');
    const tile8:ResultEquivalentProps={
        imggreen:imgGreen4,
        value:"",
        description:"",
        greenStyleClass:"green-style-4",
        alignClass:"result-tile-right",
        tileStyle:{
            rotate:'0deg'
        },
        imganimated:bath,
        tilenum:tileBAT?.value,
        tiledesc:tileBAT?.description
    }
    return (
      <div>
        <ResultEquivalentTile {...tile1}></ResultEquivalentTile>
        <ResultTileConnector {...connect1}></ResultTileConnector>
        <ResultEquivalentTile {...tile2}></ResultEquivalentTile>
        <ResultTileConnector {...connect2}></ResultTileConnector>
        <ResultEquivalentTile {...tile3}></ResultEquivalentTile>
        <ResultTileConnector {...connect1}></ResultTileConnector>
        <ResultEquivalentTile {...tile4}></ResultEquivalentTile>
        <ResultTileConnector {...connect2}></ResultTileConnector>
        <ResultEquivalentTile {...tile5}></ResultEquivalentTile>
        <ResultTileConnector {...connect1}></ResultTileConnector>
        <ResultEquivalentTile {...tile6}></ResultEquivalentTile>
        <ResultTileConnector {...connect2}></ResultTileConnector>
        <ResultEquivalentTile {...tile7}></ResultEquivalentTile>
        <ResultTileConnector {...connect1}></ResultTileConnector>
        <ResultEquivalentTile {...tile8}></ResultEquivalentTile>
      </div>
    );
}