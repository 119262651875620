import CountUpAnimation from "components/common/CountUpAnimation/CountUpAnimation";
import { ResultEquivalentProps } from "./ResultEquivalentTile.model";
import imgTileBack from "assets/img/tile-background.png";

export default function ResultEquivalentTile(tileProps: ResultEquivalentProps){  
    return (
        <div className="result-container-wrapper">
            <div className={["result-container",tileProps.alignClass].join(' ')}>
                <div  className="result-tile-num">
                    <CountUpAnimation endValue={tileProps.tilenum} />
                </div>
                <div  className="result-tile-desc">
                     {tileProps.tiledesc}
                </div>
                <div className="result-anime-image">
                    <img src={tileProps.imganimated} alt="animated"></img>
                </div>
                <div className="result-equ-tile result-foreground">
                    <img src={imgTileBack} alt="title" style={tileProps.tileStyle}></img>
                </div>
                <div className={["result-green-back", "result-background", tileProps.greenStyleClass].join(' ')}>
                    <img src={tileProps.imggreen} alt="green back"/>
                </div>
            </div>
        </div>
    );
}