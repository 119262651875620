import React, { Component } from 'react';
import styles from './Dcf.module.css';
import {Helmet} from 'react-helmet';
import background from "../../assets/img/CodeBackdrop.png";
import { Provider } from 'react-redux';
import store from 'components/redux.store';
import { Container } from 'reactstrap';
import Dcftabs from "./dcftabs/dcftabs";
export class Dcf extends Component {
  static displayName = Dcf.name;
  
  constructor(props) {
    super(props);
    this.state = { currentCount: 0 };
    this.incrementCounter = this.incrementCounter.bind(this);
  }

  incrementCounter() {
    this.setState({
      currentCount: this.state.currentCount + 1
    });
  }

  render() {
    return (
      <div className={styles.Dcf}>
         <Helmet>
                <style>
                  {`
                    body { 
                      background-image: linear-gradient(rgba(0,0,0,.8), rgba(0,0,0,.8)),url(${background});
                      background-position: center center;
                      background-attachment: fixed;
                      background-repeat: no-repeat; background-size: cover;
                    } 
                    .container { 
                      width:100% !important;
                      margin-left: auto;
                      margin-right: auto;
                  } 
                  `}
                    </style>
                <meta name="description" content="DCF is the world's first Digital Carbon Calculator for consumer digital behavior" />
                <meta name="keywords" content="digital carbon footprint calculator, digital emissions, digital sustainability, carbon footprint calculator, digital carbon footprint, digital carbon footprint calculation" />
         </Helmet>
         <Container>
         <div className="row">
          <div className="col-12">
            <Provider store={store}>
                <Dcftabs/>
            </Provider>
          </div>
         </div>
         </Container>
         
      </div>
    );
  }
}
