import React from 'react';
import styles from './FormCrypto.module.css';
import DoYouKnow from 'components/Dcf/DoYouKnow/DoYouKnow';
import DoYouKnowImage from "assets/img/Present_Crypto.jpg";
import { DoYouKnowProps } from "components/Dcf/DoYouKnow/DoYouKnow.model";
import { DeNumberInputProps } from 'components/common/DeNumberInput/DeNumberInput.model';
import DeNumberInput from 'components/common/DeNumberInput/DeNumberInput';
import { useDispatch, useSelector } from 'react-redux';
import { setFormCrypto } from '../dcf.actions';

import EmailInput from '../EmailInput/EmailInput';

 const FormCrypto=({value,backButtonClick}:any)=> {
  const [openEmailPopup, setOpenEmailPopup] = React.useState(false);

  const handleEmailClick = () => {
    setOpenEmailPopup(true);
  };

  const handleCloseEmailPopup = () => {
    setOpenEmailPopup(false);
  };

    
    const { transactions} = useSelector((state: any) => state.dcf.formCrypto);
    const dispatch = useDispatch();
    const tileProps:DoYouKnowProps={
        imgsrc:DoYouKnowImage,
        header: (
            <div style={{ width: '200px' }}>
              <span>Did you know?</span>
            </div>
          ),
          body: (
            <div style={{ width: '160px' }}>
              <div>
                 A Bitcoin transaction consumes roughly the same energy needed for 1.2 million Visa transactions
              </div>
            </div>
          ),
         position:"left-top"
    }
    const numberOnline:DeNumberInputProps={
      label:"Enter Transaction Per Year",
      value:transactions,
      onChange:(value: number) =>{
        dispatch(setFormCrypto(value));
      },
      width:240,
      id:"deNumberTransactions"
    }
    
    return(
        <div className={[styles.topcrypto,"topform"].join(" ")}>
             <div className="row">
                <div className="col-lg-6 col-12 col-sm-12">
                <div className={styles.topformcrypto}>
                    <div className="formcontent  pt-4 pb-4">
                      <div className="row mt-5">
                        <div className="col-12">
                          <div className="row">
                            <div className="col-12 text-center">
                                If active in the crypto space, how many cryptos (e.g. Bitcoin)  digital transactions do you make in a year? 
                            </div>
                          </div>
                          <div className="row mt-5">
                            <div className="col-12 text-center">
                            <DeNumberInput {...numberOnline} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                            <div className="col-6 pt-2">
                                <span className="backbutton" onClick={()=>{backButtonClick(value)}}>&lt; Back</span>
                            </div>
                            <div className="col-6 text-end">
                                 <button className="btn btn-green btn-sm mt-1" onClick={()=>{handleEmailClick()}}>Submit</button>
                                 <EmailInput open={openEmailPopup} onClose={handleCloseEmailPopup} />
                            </div>
                    </div>
                  </div>  
                </div>
                <div className="col-lg-6 col-12 col-sm-12">
                    <DoYouKnow {...tileProps}></DoYouKnow>
                </div>
            </div>
        </div>
    );
}

export default  FormCrypto;